<template>
  <b-nav-item @click="toggleTheme">
    <feather-icon
      size="21"
      :icon="isDark ? 'SunIcon' : 'MoonIcon'"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed, ref, onMounted } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()
    const user = ref(null)
    const isDark = computed(() => skin.value === 'dark')
    
    // URL da API
    const API_URL = "https://api.intranet-dev.it4d.com.br/api/v1/me"
    const UPDATE_THEME_URL = "https://api.intranet-dev.it4d.com.br/api/v1/user/update-theme"

    // Função para buscar os dados do usuário usando POST
    const fetchUserData = async () => {
      try {
        const response = await axios.post(API_URL, {}, { 
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}` 
          }
        })

        user.value = response.data
        if (user.value.theme) {
          skin.value = user.value.theme
          localStorage.setItem('me', JSON.stringify(user.value)) 
        }
      } catch (error) {
        console.error('Erro ao buscar usuário:', error)

        // Se a API falhar, tenta pegar do localStorage
        const storedMe = localStorage.getItem('me')
        if (storedMe) {
          try {
            user.value = JSON.parse(storedMe)
            skin.value = user.value.theme
          } catch (err) {
            console.error('Erro ao carregar tema do localStorage:', err)
          }
        }
      }
    }

    // Alternar entre modo claro e escuro
    const toggleTheme = async () => {
      const newTheme = isDark.value ? 'light' : 'dark'

      try {
        await axios.post(UPDATE_THEME_URL, { theme: newTheme }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })

        skin.value = newTheme

        // Atualiza no localStorage
        if (user.value) {
          user.value.theme = newTheme
          localStorage.setItem('me', JSON.stringify(user.value))
        }

        console.log("Tema atualizado para:", newTheme)
      } catch (error) {
        console.error("Erro ao atualizar o tema:", error)
      }
    }

    onMounted(fetchUserData)

    return { skin, isDark, toggleTheme }
  },
}
</script>
